<template>
    <!-- Page: pages/program-contacts -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Program Contacts</h1>
        <userTools/>
    </div>

    <hr/>

    <div class="container">

        <general-container
            :customClass="'column'"
            :isLocked="false"
        >

            <!--div class="mb-30 browse-contacts-filter-wrapper">
                <p class="card__info__label step-label mb-5">
                    <label>Select a Program</label>
                </p>
                <div class="mb-30">
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="programs ? programs.map(item => ({value: item.locationId, name: item.name})) : []"
                        name="program"
                        placeholder="Select a Program..."
                        v-model:value="selectedProgramLocationId"
                    ></custom-select>
                </div>
            </div-->

            <preloader v-if="loading"></preloader>

            <p class="agency-name">{{ program.agency.AgencyRef.name }}</p>
            <h2 v-if="program && !loading">{{ program.name }}</h2>

            <div class="browse-contacts-filter-wrapper" v-show="selectedProgramLocationId">
                <search-block :type="'filter'" :title="'Filter Contacts By Name or Position'" :placeholder="'Start typing to narrow results.'"  v-on:search-keyup="search = $event"/>
            </div>

            <div class="container" v-if="contacts.length > 0">
                <div class="toggle">
                    <input id="view-toggle"
                           class="switch is-rounded"
                           v-on:click="toggleView()" type="checkbox"
                           :checked="contactView"
                           name="agencies[]"
                    >
                    <label for="view-toggle"
                           class="agency-filter-label">{{ !contactView ? 'Show Table View' : 'Show Card View' }}</label>
                </div>
            </div>
            <div class="container">
                <table class="zebra" v-if="contacts.length > 0 && contactView">
                    <thead>
                    <tr class="tbl-sortable">
                        <th @click="sortField = 'first_name'; sortIsAsc = !sortIsAsc">
                            First name {{ sortField === 'first_name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th @click="sortField = 'last_name'; sortIsAsc = !sortIsAsc">
                            Last name {{ sortField === 'last_name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th @click="sortField = 'salary'; sortIsAsc = !sortIsAsc">
                            Salary {{ sortField === 'salary' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th class="not-sortable">Position</th>
                        <th class="not-sortable">Program</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="contact in contacts">
                        <td><a :href="contact.url">{{ contact.firstName }}</a></td>
                        <td><a :href="contact.url">{{ contact.lastName }}</a></td>
                        <td class="is-family-monospace has-text-right">${{ priceFormat(contact.salary) }}</td>
                        <td>
                            <a :href="contact.position.PositionRef.url" v-if="contact.position?.PositionRef">
                                {{ positionName(contact) }}
                            </a>
                        </td>
                        <td>
                            <a v-if="program" :href="program.url">{{ program.name }}</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="container">
                <div class="columnizer">
                    <contact-information-card
                        v-if="!contactView"
                        v-for="contact in contacts"
                        v-bind="contact"
                        :key="contact.contentId"
                        :contactUrl="contact.url"
                        :agency="contact.agency.AgencyRef"
                        cardtoolsize="small"
                    />
                </div>
            </div>
            <pagination
                v-if="!loading && selectedProgramLocationId && pageCount > 1"
                @paginated="page = $event"
                :current="page"
                :pageCount="pageCount"
            />
            <preloader v-if="loadingMoreCount > 0"/>

            <div class="error-message" v-if="!loading && selectedProgramLocationId && contacts.length === 0">
                <span>😬 Bummer!</span>
                <p>We couldn't find any results for that.<br />Are you sure you spelled everything correctly?</p>
            </div>
        </general-container>
    </div>
    <!-- End Page: pages/program-contacts -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import ContactInformationCard from "./contact-info-card";
    import SearchBlock from "./search-block";
    import {computed, ref} from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import CustomSelect from "./custom-select";
    import Pagination from "./pagination";
    import { priceFormat } from "@/helpers/filter";
    import { v4 as uuidv4 } from "uuid";
    
    export default {
        components: {
            Pagination,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            ContactInformationCard,
            SearchBlock,
            Preloader,
            CustomSelect,
        },
        data() {
            return {
                data: [],
                selectedProgramLocationId: '',
                program: null,
                page: 1,
                pageCount: 1,
                pageSize: 24,
                loading: true,
                loadingMoreCount: 0,
                sortField: 'salary',
                sortIsAsc: false,
                search: '',
                cardtoolsize: "small",
                contacts: []
            };
        },
        mounted() {
            if (this.$route.query.programId) {
                this.loadProgram(this.$route.query.programId);
                this.selectedProgramLocationId = this.$route.query.programId;
            } else {
                this.loading = false;
            }
        },
        methods: {
            keyup(s) {
                this.search = s;
            },
            loadProgram(id) {
                RequestHandler.loadProgram(id).then(response => {
                    this.program = response.ProgramRef;
                });
            },
            positionName(contact) {
                let name = contact.position.PositionRef && contact.position.PositionRef.name || contact.position.title;
                if (contact.positionRole) {
                    name += ' (' + contact.positionRole + ')';
                }

                return name;
            },
            toggleView(){
                this.contactView = !this.contactView;
            },
            loadContacts() {
                this.loading = true;
                this.contacts = [];

                RequestHandler.loadFullContactsByProgram(
                    this.selectedProgramLocationId, (this.page - 1) * this.pageSize, this.pageSize, this.sortField,
                    this.sortIsAsc ? 'asc' : 'desc', this.sortField === '', this.search
                ).then(response => {
                    this.contacts = response.ContentList.list;
                    this.pageCount = response.ContentList.pageCount;
                    this.loading = false;
                }).catch((error) => {
                    console.error(error);
                    this.loading = false;
                    this.pageCount = 1;
                });
            },
        },
        watch: {
            selectedProgramLocationId() {
                this.loadContacts();
                this.$router.push({name: 'program-contacts', query: {'programId': this.selectedProgramLocationId}});
            },
            search() {
                this.page = 1;
                this.pageCount = 1;
                if (this.typingTimer) {
                    clearTimeout(this.typingTimer);
                }
                this.typingTimer = setTimeout(this.loadContacts, 200);
            },
            page() {
                this.loadContacts();
            },
            sortIsAsc() {
                this.loadContacts();
            }
        },
        setup(props) {

            const uniqueId = computed(() => {
                return uuidv4();
            });
            const store = useStore();
            const contactView = ref(false);
            const showSearch = computed(() => store.getters.showSearch);

            return {
                path: [{title: 'Program Contacts'}],
                showSearch,
                contactView,
                priceFormat,
                uniqueId,
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";


    .error-message {
        width: 100%;
        text-align: center;
        font-size: 1.25em;
        span {
            font-size: 1.5em;
            font-weight: 600;
        }
    }

    .browse-contacts-filter-wrapper {
        max-width: 50%;
        margin: auto;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }

        .card__info__label {
            font-weight: 600;
            color: $blue--dark
        }
    }

    .step-label {
        font-size: 1.75em;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .custom-select {
        width: 100%;
    }
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }

    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }

        &.zebra {
            width: 100%;
        }
    }

    .zebra tr:nth-child(even) {
        //background: #eee;
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }

    .tbl-sortable th {
        cursor: pointer;

        &.not-sortable {
            cursor: default;
        }
    }
    .toggle {
        margin-left: 15px;
    }
    .agency-name {
        font-weight: 400;
        margin-bottom: 0!important;
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-family: $body;
        color: $primary;
    }
</style>
